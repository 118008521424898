<template>
  <div id="Invitational">
    <van-overlay :show="showOver" @click="showOver = false" />
    <div v-if="showOver" class="shareTop">
      <div>
        <img src="../../assets/image/findOut/arrow.png"/>
      </div>
      <p>点击分享拿好礼</p>
    </div>
    
    <img class="header" src="img/share001.jpg" />


    <div class="main">
      <img class="header" src="img/share002.jpg" />
      <!-- 立即分享按钮 @click="shareLink"-->
      <img class="button" @click="shareNew" src="img/button.png" />
    </div>
    <div class="main">
      <img class="header" src="img/share03.jpg" />
      <div class="getMoney">
        <div>
          <p><span>{{inviteAward.reward}}</span><span>元</span></p>
          <img class="header" @click="toWx" src="img/dh.png" />
        </div>
        <div @click="toList">
          <p><span>{{inviteAward.inviteCount}}</span><span>人</span></p>
          <img class="header" src="img/yq1.png" />
        </div>
      </div>
    </div>
    <img class="header" src="img/share04.jpg" />
    <!-- 转发弹框 合成海报-->
    <van-popup v-model="showImg" get-container="#MemberProduct" style="background:none;width:75%">
      <img id="myPoster" >
      <img id="qrcode" style="opacity:0" :src="this.shareImg" >
      <span></span>
      <img id="poster" v-if="imgType == 1" style="width:100%;opacity:0" src="img/code5.jpg" >
      <img id="poster" v-else style="width:100%;opacity:0" src="img/code6.jpg" >
    </van-popup>

    <!-- 邀请记录弹框 -->
    <van-popup v-model="recordShow" get-container="#MemberProduct" style="background:none;width:75%;height:70%;overflow:auto; border-radius: 0.1rem;">
      <div class="record">
        <div>共邀请<span>{{inviteAward.inviteCount}}</span>人</div>
        <div class="recordCent">
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
          <p><span>昵称</span><span>返现</span><span>时间</span></p>
        </div>
      </div>
    </van-popup>
    

    <!-- 提现弹框 -->
    <van-popup v-model="showWX" get-container="#MemberProduct" style="background:none">
      <div class="productBuy">
        <div>
          <p>奖励兑换</p>
          <img src="img/add_qrcode.jpg" />
          <p class="fast">请添加大咖会小助手帮助您快速提现</p>
        </div>
        <div @click="showWX = false" class="van-hairline--top van-dialog__footer"><button class="van-button van-button--default van-button--large van-dialog__confirm"><div class="van-button__content"><span class="van-button__text">确认</span></div></button></div>
      </div>
    </van-popup>

  </div>
</template>
<script>
import v from '@/main'
export default {
  name: 'Invitational',
   data(){
    return{
      inviteAward:'',
      imgType:'',
      showOver:false,
      options: [
        { name: '微信', icon: 'wechat' },
        { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'poster' },
        { name: '二维码', icon: 'qrcode' },
      ],
      showWX:false,
      showImg:false,
      shareImg:'',
      recordShow:false
    }
  },
  mounted(){
    this.axios({
      method: 'GET',
      url: '/user/inviteAward',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.inviteAward = res.data.data
        // console.log(this.inviteAward )
        // this.shareNew()
      }
    })
  },
  created(){
    // var _this = this;
    // this.axios({
    //   method: 'GET',
    //   url: '/introduce',
    //   headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    // }).then((res) => {
    //   if(res.data.code == 0){
    //     _this.getShareConfig({
    //       title: 'Hi，我推荐保观大咖会新锐会员给你。',
    //       desc: '在线分享、干货报告、线下活动、会员交流一起打包给你',
    //       inviteCode:res.data.data.inviteCode
    //     },_this)
    //   }
    // })
  },
  methods:{
    // 跳转列表
    toList(){
      this.$router.push('/InviteList')
    },
    inviteRecord(){
      this.axios({
        method: 'GET',
        url: '/user/inviteRecord',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.recordShow = true

        }
      })

    },
    // 兑换
    toWx(){
      if(this.inviteAward.reward<100){
        this.$dialog.alert({
          title: '奖励兑换',
          message: '您可兑换的金额尚未达到100元，无法进行正常兑换。',
        }).then(() => {
          // on close
        });
      }else{
        this.showWX = true
      }
      
    },
    getShareConfig(shareContent,v){
      var _this = this;
      v.axios({
        method: 'GET',
        url:`/wechat/js-sdk?from=${v.base64.encode(window.location.href)}`,
        headers: {
          'Authorization': `Bearer ${_this.Global.getCookie('tokenDkhNww')}` 
        },
      }).then( (res) => {
        if(res.data.code == 0){
          _this.wxset(res.data.data.config ,shareContent)
        }else{
          console.log(res.data.message,'=====')
        }
      })
    },
    wxset(config, shareContent){
      v.wx.config(config);
      v.wx.ready( () => { 
        v.wx.onMenuShareAppMessage({ 
          title: shareContent.title, 
          desc: shareContent.desc,
          link: window.location.protocol+"//"+window.location.host+'/MemberProduct?inviteCode='+shareContent.inviteCode, //
          imgUrl: this.Global.nowurl+'/share-logo.png', 
          success() {
            // 设置成功
          }
        }); 
        v.wx.onMenuShareTimeline({ 
          title: shareContent.title,
          desc: shareContent.desc, 
          link: location.href.split('?')[0]+'?inviteCode='+shareContent.inviteCode,
          imgUrl: this.Global.nowurl+'/share-logo.png', 
          success() {
            // 设置成功
          }
        });
      })
    },
    // 分享海报
    shareNew(){
      this.axios({
        method: 'GET',
        url: '/introduce/transmitRedeemCode',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.shareImg = res.data.data.code
          this.showImg = true
          this.imgType = res.data.data.type
          setTimeout(function(){
            var canvas,width,height;
            width = document.getElementById("poster").offsetWidth;
            height = document.getElementById("poster").offsetHeight;

            canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;

            // 画一个和海报一样大小的背景
            var context = canvas.getContext("2d");
            context.rect(0 , 0 , width , height);
            context.fillStyle = "#fff";
            context.fill();
            
            // 海报
            var myImage2 = new Image();
            myImage2.src = document.getElementById("poster").getAttribute("src"); 
            myImage2.crossOrigin = 'Anonymous';
            myImage2.onload = function(){
                context.drawImage(myImage2 , 0 , 0 , width ,  height);
                // 名字
                var text=res.data.data.name;
                context.font = 'bolder 14px Microsoft YaHei';
                context.fillStyle = '#f83b4f';
                context.fillText(text, width*13/31 , height*1266/2060);
                // 二维码
                var myImage = new Image();
                myImage.src = document.getElementById("qrcode").getAttribute("src"); 
                myImage.crossOrigin = 'Anonymous';
                myImage.onload = function(){
                    context.drawImage(myImage , width*7/25 , height*3/8 , width*11/24 , width*11/24);
                    var base64 = canvas.toDataURL("image/png");     //获取base64的图片流
                    var img = document.getElementById('myPoster');
                    img.setAttribute('src' , base64);
                    document.getElementById("poster").remove()
                    document.getElementById("qrcode").remove()
                }
            }
          },500)


          // console.log(this.shareImg)
        }
      })
    },
    // 分享
    shareLink(){
      this.axios({
        method: 'GET',
        url: '/introduce',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          // this.shareImg = res.data.data
          // this.showImg = true
          // console.log(this.shareImg)
          // _this.getShareConfig({
          //   title: 'Hi，我推荐保观大咖会新锐会员给你。',
          //   desc: '在线分享、干货报告、线下活动、会员交流一起打包给你',
          //   inviteCode:res.data.data.inviteCode
          // },_this)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
#Invitational{
  .record{
    background: #FFFFFF;
   
    >div:first-child{
      text-align: center;
      padding: 0.1rem 0 0.15rem;
      font-size: 0.16rem;
      color: #171717;
      border-bottom: 1px solid #dad3d361;
      >span{
        font-weight: bold;
        padding: 0 0.1rem;
        font-size: 0.3rem;
        color: #fb6639;
      }
      
    }
    .recordCent{
      padding-top: 0.1rem;
      >p{
        display: flex;
        padding: 0.05rem 0;
        color: #3D404F;
        >span{
          flex: 1;
          text-align: center;
        }
        >span:nth-child(2){
          color: #fd2438;
          font-size: 0.15rem;
        }
      }
    }
  }
  
  .productBuy{
    border-radius: 0.1rem ;
    overflow: hidden;
    >div:first-child{
      background: #FFFFFF;
      width: 2.73rem;
      padding: 0 0.2rem;
      box-sizing: border-box;
      overflow: hidden;
      >p{
        color: #3D404F;
        padding: 0.15rem;
        text-align: center;
        font-size: 0.15rem;
      }
      >img{
        width: 100%;
      }
      .fast{
        color: #989AB1;
        font-size: 0.12rem;
      }
    }
  }
  position: relative;
  .shareTop{
    position: fixed;
    top: 0;
    z-index: 10;
    >div{
      text-align: right;
       >img{
        width: 80%;
        transform: rotate(-30deg);
        display: inline-block;
      }
    }
    >p{
      color: #ffff;
      text-align: center;
      margin-top: 0.5rem;
      font-size: 0.18rem;
      font-weight: 900;
      text-shadow: 2px 4px 7px #af3d3d;
    }
  }
  .main{
    position: relative;
    .getMoney{
      display: flex;
      justify-content: space-between;
      width: 2.8rem;
      >div{
        width: 1.33rem;
        height: 0.74rem;
        background: url(../../../public/img/bg.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        >p{
          display: flex;
          align-items: baseline;
          >span:first-child{
            font-size: 0.3rem;
            font-weight: 900;
            color: #171717;
          }
          >span:last-child{
            color: #171717;
            font-size: 0.15rem;
            font-weight: normal;
            display: block;
          }
        }
        >img{
          // height: 0.21rem;
          width: 0.83rem;
        }
      }
    }
    .button{
      width: 2.5rem;
    }
    .button,.getMoney{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .header{
    width: 100%;
  }
}
</style>